import * as React from "react"
import { Link } from "gatsby"
import { menuItems } from "../../constants/links"
import { FooterStyles, FooterMenuStyles, CopyrightStyles } from "./FooterStyles"
import useAllService from "../../hooks/use-all-service"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"

const Footer = () => {
  const allService = useAllService()
  const siteMeta = UseSiteMetadata()
  return (
    <FooterStyles style={{ marginBottom: 0 }} className="section">
      <div className="container container__tight">
        <FooterMenuStyles className="footer__menu">
          <h5>Links</h5>
          <ul>
            {menuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.path} activeClassName="menu__item--active">
                    {item.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </FooterMenuStyles>
        {allService.length > 0 && (
          <FooterMenuStyles className="footer__menu services__menu">
            <h5>
              <Link to="/services">
                All Services<span>.</span>
              </Link>
            </h5>
            <ul>
              {allService.map((item, index) => {
                const { gatsbyPath, title } = item

                return (
                  <li key={index}>
                    <Link to={gatsbyPath}>
                      {title}
                      <span>.</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </FooterMenuStyles>
        )}
      </div>
      <CopyrightStyles>
        <div className="container container__tight">
          <p>
            Published by{" "}
            <a
              href="https://mortimer.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mortimer.xyz
            </a>{" "}
            using design and assets by{" "}
            <a
              href={siteMeta.developerUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {siteMeta.developerName}
            </a>
            <span>.</span>
          </p>
        </div>
      </CopyrightStyles>
    </FooterStyles>
  )
}

export default Footer
