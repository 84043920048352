export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/services",
    text: "services",
  },
  {
    path: "/location",
    text: "location",
  },
  {
    path: "/contact",
    text: "contact",
  },
]
